.cell-view {
  border: 1px solid transparent;
  padding: 0px 12px;
}
.cell-focused {
  border: 1px dashed #968cff;
  background-color: #968cff1a;
}
.cell-disabled {
  color: gray;
  width: 100%;
  background: repeating-linear-gradient(
    38deg,
    #c0c0c021,
    #c0c0c021 10px,
    #d8d8d81c 10px,
    #d8d8d81c 20px
  );
}



.ant-table-tbody > tr > td {
  padding: 0;
}

.editable-table .ant-select,
.editable-table .ant-input-number {
  width: 100%;

}



