.TextEditor {
    appearance: none;
    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    padding-block: 0;
    padding-inline: 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: var(--rdg-color);
    background-color: var(--rdg-background-color);
    font-family: inherit;
    font-size: var(--rdg-font-size);
  }
  .TextEditor:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }
  .TextEditor::placeholder {
    color: #999;
    opacity: 1;
  }
  